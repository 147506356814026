import React from 'react'
import { useFullLocale } from '@logdoc/l10n';
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import { L10nProvider } from '@logdoc/l10n';

const Ver = () => {
    return (
        <L10nProvider>
            <Versions />
        </L10nProvider>
    )
}

export const Versions = () => {
    const [t, _, locale] = useFullLocale()
    const downloadText = locale === 'ru' ? 'скачать' : 'download'
    const contact = locale === 'ru' ? 'свяжитесь с нами' : 'contact us'

    return (
        <LayoutDefault>
            <section className={classes.sec}>
                <h2 className={classes.secTitle}>
                    {t('versions.title')}
                </h2>
                <div className={classes.secDescription}>
                    <p>
                        {t('versions.p1')}
                    </p>
                    <ul>
                        <li>
                            <span className={classes.textOrange}>Portable</span> - {t('versions.p2')} <a target="_blank"
                                                                                         href="/logdoc-portable-latest.tgz">{downloadText}</a>
                        </li>
                        <li>
                            <span className={classes.textOrange}>Community</span> - {t('versions.p3')} <a target="_blank" href="/logdoc-community-latest.tgz">{downloadText}</a>
                        </li>
                        <li>
                            <span className={classes.textOrange}>Pro</span> - {t('versions.p4')} <a
                            href="mailto:biz@logdoc.org?subject=LogDoc-Pro">{contact}</a>.
                        </li>
                    </ul>
                </div>
            </section>
        </LayoutDefault>
    );
};

export default Ver;
